<template>
  <BaseListPage locale-section="pages.hundreds" route="hundred">
    <HundredsList />
  </BaseListPage>
</template>

<script>
export default {
  name: "HundredsPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    HundredsList: () => import("./HundredsList")
  }
};
</script>
